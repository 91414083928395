<template>
  <v-container grid-list-md fill-height>
    <v-layout align-center justify-center>
      <v-flex xs10 sm7 md5>
        <v-card>
          <v-layout align-start justify-center row fill-height>
            <v-flex xs9 sm9 md9>
              <DropdownTranslate />
              <h1>{{ $t('auth.passwordNew.title') }}</h1>
              <p>
                {{ $t('auth.passwordNew.description') }}
              </p>
              <v-form
                ref="form"
                v-model="validForm"
                @submit.prevent="resetPassword"
              >
                <v-text-field
                  v-model="username"
                  :label="$t('auth.username')"
                  :rules="[rules.required, rules.username]"
                  backgroundColor="white"
                  solo
                  disabled
                  append-icon="mdi-account-outline">
                </v-text-field>
                <v-text-field
                  v-model="password"
                  :append-icon="passwordVisibility ? 'visibility' : 'visibility_off'"
                  :type="passwordVisibility ? 'text' : 'password'"
                  name="input-10-1"
                  :label="$t('auth.password')"
                  :rules="[rules.required, rules.min, rules.password]"
                  required
                  @click:append="passwordVisibility = !passwordVisibility"
                  backgroundColor="white"
                  solo
                ></v-text-field>
                <v-text-field
                  v-model="confirmPassword"
                  :append-icon="passwordVisibility ? 'visibility' : 'visibility_off'"
                  :type="passwordVisibility ? 'text' : 'password'"
                  name="input-10-1"
                  :label="$t('auth.confirmPassword')"
                  :rules="[rules.required, rules.confirmed]"
                  required
                  @click:append="passwordVisibility = !passwordVisibility"
                  backgroundColor="white"
                  solo
                ></v-text-field>
                <v-layout align-center justify-center row fill-height>
                  <v-btn
                    :disabled="!validForm"
                    type="submit"
                    class="white--text"
                    color="primary"
                    @click="validate()">{{ $t('auth.passwordNew.button') }}
                  </v-btn>
                </v-layout>
                <div class="my-6">
                  <p class="error--text" v-if="error">{{ $t('auth.passwordReset.error') }}</p>
                </div>
              </v-form>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'ResetPassword',
  data() {
    return {
      validForm: true,
      passwordVisibility: false,
      password: '',
      confirmPassword: '',
      error: false,
      username: decodeURIComponent(this.$route.query.username || ''),
      rules: {
        required: (value) => !!value || this.$t('misc.required'),
        min: (v) => v.length >= 8 || this.$t('misc.min8Chars'),
        confirmed: (value) => value === this.password || this.$t('misc.differentPassword'),
        username: (v) => (v.length >= 1 && v.length <= 128) || this.$t('misc.invalidUsername'),
        password: (value) => {
          const pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
          return pattern.test(value) || this.$t('misc.invalidPassword');
        },
      },
      snackbarMessageId: null,
    };
  },
  methods: {
    /**
    * Ensure that form is valid before making backend request
    */
    validate() {
      this.errors = false;
      if (this.$refs.form.validate()) {
        this.resetPassword();
      }
    },
    /**
    * Show a snackboard message popup
    *
    * @param { string } title
    * @param { string } description
    * @param { string } color
    *
    */
    showMessage(title, description, color) {
      const data = {
        loading: false,
        title,
        description,
        color,
        timeout: 5000,
      };
      this.$store.commit('snackbars/ADD_MESSAGE', data);
    },
    async resetPassword() {
      let title = this.$t('auth.passwordNew.error');
      let description = this.$t('auth.passwordReset.error');
      let color = 'error';
      let changed;
      let redirect;
      try {
        ({ changed, redirect } = await this.$store
          .dispatch('auth/NEW_PASSWORD', this.password));
        if (!changed) {
          this.error = true;
          return;
        }
        title = this.$t('auth.passwordNew.success');
        description = this.$t('auth.passwordNew.successDescription');
        color = 'success';
      } catch (error) {
        this.error = true;
        description = error.message;
      }

      this.showMessage(title, description, color);
      if (changed && redirect) {
        window.location = '/';
      }
    },
  },
  metaInfo() {
    return {
      title: this.$t('auth.passwordNew.title'),
    };
  },
};
</script>
